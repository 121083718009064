/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import { USER_FLAGGED, USER_IGNORED } from './BrandSafetyCheckbox';
import { BRAND_SAFETY_MEASURE_STATUS } from './brandSafetyConsts';

export function parseUserFeedbackRecord(record) {
  if (!record) {
    return null;
  }

  let notes = [];
  let flagFeedback = {};
  try {
    notes = JSON.parse(record.notes);
  } catch (e) {}

  try {
    flagFeedback = JSON.parse(record.flagFeedback);
  } catch (e) {}

  return {
    ...record,
    notes,
    flagFeedback,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function formatBranSafetyData(
  influencerList,
  userFeedbackData,
  campaignBrandSafetyPreference
) {
  const flaggedPostList = [];

  influencerList?.forEach(infData => {
    infData.flaggedPosts?.forEach(post => {
      const resPost = {
        creatorReportDetailsId: infData.creatorReportDetailsId,
        postDetails: post.postDetails,
        creatorPostId: post.creatorPostId,
      };

      const resFlags = [];
      const AIDetectedFlags = [];

      const userFeedbackRecord = userFeedbackData.find(
        record => record.creatorPostId === post.creatorPostId
      );

      post.flags.forEach(flagObj => {
        const flagKey =
          flagObj.type.charAt(0).toUpperCase() + flagObj.type.slice(1);

        AIDetectedFlags.push(flagKey);
        // ignore if the flag is off in the preference setting
        if (!campaignBrandSafetyPreference[flagKey]) {
          return;
        }

        // ignore if user manually ignored the flag
        if (userFeedbackRecord?.flagFeedback[flagKey] === USER_IGNORED) {
          return;
        }

        resFlags.push(flagKey);
      });

      // append user added flags
      _.entries(userFeedbackRecord?.flagFeedback).forEach(([flag, value]) => {
        if (value === USER_FLAGGED) {
          resFlags.push(flag);
        }
      });

      const reviewStatus =
        userFeedbackRecord?.status || BRAND_SAFETY_MEASURE_STATUS.NOT_REVIEWED;

      resPost.flags = resFlags;
      resPost.AIDetectedFlags = AIDetectedFlags;
      resPost.userFeedback = userFeedbackRecord;
      resPost.reviewStatus = reviewStatus;

      flaggedPostList.push(resPost);
    });
  });

  // console.log(influencerList);
  // console.log(userFeedbackData);
  // console.log(campaignBrandSafetyPreference);
  // console.log(flaggedPostList);

  return flaggedPostList;
}
