import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import arClient from '../../util/api_client';
import { ENV } from '../../constants/apiUrls';
import { BRAND_SAFETY_LABELS } from '../Persona/BrandSafety/brandSafetyConsts';

const initialState = {
  campaignList: [],
  campaignListLoading: false,
  ROIDashboardCampaignData: {},
  ROIDashboardData: {},
  ROIDashboardDataUpdated: null,
  ROIDashboardDataLoading: false,
  ReportingDataResponce: {},
  ReportingData: {},
  ReportingDataLoading: false,
  isCreateCampaignLoading: false,
  latestSavedCampaign: null,
  errorMsg: null,
  campaignCreators: [],
  isCampaignCreatorsFetched: false,
  fetchCampaignCreatorsLoading: false,
  isCampaignDashboardLoading: false,
  isOutreachBudgetLoading: false,
  outreachBudgetData: {},
  outreachNotesData: {},
  isOutreachNotesLoading: false,
  outreachData: {
    contactDetails: [],
    emails: [],
    outreachId: null,
  },
  revisionDetailsData: {
    campaignAndCreatorDetails: [],
    revisionDetails: {},
  },
  influencerAgreementCountData: {},
  isInfluencerAgreementCountLoading: false,
  outreachEmailAddresses: [],
  isOutreachEmailAddressesLoading: false,
  tikTokSparkAds: null,
  campaignCreatorsFetchedId: null,
};

export const defaultDeliverables = {
  tiktok: {
    video: 10,
  },
  twitter: {
    post: 0,
  },
  instagram: {
    post: 0,
    reels: 0,
    story: 0,
  },
  youtube: {
    shorts: 0,
    video: 0,
  },
};

export const defaultTermsOrganic = {
  tiktok: 90,
  twitter: 90,
  instagram: 90,
  youtube: 90,
};

export const defaultTermsPaid = {
  tiktok: 7,
  twitter: 7,
  instagram: 7,
  youtube: 7,
};

export const defaultCampaignFormValues = {
  flight_end: '',
  flight_start: '',
  flight_tz: 'UTC -5 (EST / New York)',
  region: 'US-California',
  id: '',
  name: '',
  status: '',
  budget: '',
  // campaignDetails
  campaignDescription: '',
  productName: '',
  productDescription: '',
  videoGuidelines: '',
  creatorBrief: '',
  deliverables: defaultDeliverables,
  // NEW Campaign Data
  successDefinition: '',
  campaignGoals: '',
  campaignObjectives: '',
  objectiveDetails: '',

  // Creator Brief
  creatorsAmount: '',
  priceRange_min: 10,
  priceRange_max: 100,
  videoDuration_min: null,
  videoDuration_max: null,
  briefMusicNeeded: '',
  briefCreatorShouldDo: '',
  briefCreatorShouldAvoid: '',
  briefTechRequiroments: '',
  briefCaptions: '',
  briefHashtags: '',
  brandAssets: [],
  brandAssetsSubmited: [],

  // Usage Rights
  termsOrganic: defaultTermsOrganic,
  termsPaid: defaultTermsPaid,

  // FTC
  ftcLink: '',
  ftcAssets: [],
  ftcAssetsSubmited: [],
  COPPA: false,
  alcohol: false,
  pharmaISI: false,

  // campaignBrandDetails
  brandName: '',
  brandDescription: '',
  brandInstagramLink: '',
  brandYoutubeLink: '',
  brandTwitterLink: '',
  brandTiktokLink: '',

  // Spark AD Authorization
  sparkAdsRequestsd: false,
  sparkAdsID: '',
  advertiserIDs: null,
  advertiserIdsLoading: false,

  // Docusign
  envelopesList: [],
  envelopesListLoading: false,

  // init brand safety config with all flags enabled
  brandSafetyFlagSetting: _.mapValues(BRAND_SAFETY_LABELS, () => true),
};

const CampaignsSlice = createSlice({
  name: 'Campaigns',
  initialState,
  reducers: {
    resetAll(state, action) {
      state.campaignList = initialState.campaignList;
    },
    setCampaignList(state, action) {
      const {
        payload: { list },
      } = action;
      state.campaignList = list;
    },
    setCampaignListLoading(state, action) {
      const { payload } = action;
      state.campaignListLoading = payload;
    },
    setROIDashboardData(state, action) {
      state.ROIDashboardData = action.payload;
    },
    setROIDashboardCampaignData(state, action) {
      const {
        payload: { campaign },
      } = action;
      state.ROIDashboardCampaignData = campaign;
    },
    setROIDashboardForecastBudgetData(state, action) {
      const { payload } = action;
      state.ROIDashboardData.forecast_suggestedMonthlySpend = payload;
    },
    setROIDashboardDataLoading(state, action) {
      const { payload } = action;
      state.ROIDashboardDataLoading = payload;
    },
    setROIDashboardDataUpdated(state, action) {
      const { payload } = action;
      state.ROIDashboardDataUpdated = payload;
    },
    setIsCreateCampaignLoading(state, action) {
      const { payload } = action;
      state.isCreateCampaignLoading = payload;
    },
    setLatestSavedCampaign(state, action) {
      const { payload } = action;
      state.latestSavedCampaign = payload;
    },
    setErrMsg(state, action) {
      const { payload } = action;
      state.errorMsg = payload;
    },
    setCampaignCreatorData(state, action) {
      const { payload } = action;
      state[payload.key] = payload.value;
    },
    setCampaignDashboardLoading(state, action) {
      const { payload } = action;
      state.isCampaignDashboardLoading = payload;
    },
    setOutreachBudgetData(state, action) {
      const { payload } = action;
      state.outreachBudgetData = payload;
    },
    setOutreachBudgetDataLoading(state, action) {
      const { payload } = action;
      state.isOutreachBudgetLoading = payload;
    },
    setOutreachNotesData(state, action) {
      const { payload } = action;
      state.outreachNotesData = payload;
    },
    setOutreachNotesDataLoading(state, action) {
      const { payload } = action;
      state.isOutreachNotesLoading = payload;
    },
    setOutreachData(state, action) {
      state.outreachData = action.payload;
    },
    resetOutreachData(state, action) {
      state.outreachData = {
        contactDetails: [],
        emails: [],
        outreachId: null,
      };
    },
    setInfluencerAgreementCount(state, action) {
      const { payload } = action;
      state.influencerAgreementCountData = payload;
    },
    setInfluencerAgreementCountLoading(state, action) {
      const { payload } = action;
      state.isInfluencerAgreementCountLoading = payload;
    },
    setRevisionDetailsData(state, action) {
      state.revisionDetailsData = action.payload;
    },
    setOutreachEmailAddressesData(state, action) {
      state.outreachEmailAddresses = action.payload;
    },
    setOutreachEmailAddressesDataLoading(state, action) {
      state.isOutreachEmailAddressesLoading = action.payload;
    },
    setTikTokSparkAds(state, action) {
      const { payload } = action;
      state.tikTokSparkAds = payload;
    },
    setAdvertiserIDList(state, action) {
      const { payload } = action;
      state.advertiserIDs = payload;
    },
    setAdvertiserSparkLoading(state, action) {
      const { payload } = action;
      state.advertiserIdsLoading = payload;
    },
    setEnvelopesList(state, action) {
      const { payload } = action;
      state.envelopesList = payload;
    },
    setEnvelopesListLoading(state, action) {
      const { payload } = action;
      state.envelopesListLoading = payload;
    },

    setReportingDataResponce(state, action) {
      state.ReportingDataResponce = action.payload;
    },
    setReportingData(state, action) {
      state.ReportingData = action.payload;
    },
    setReportingDataLoading(state, action) {
      state.ReportingDataLoading = action.payload;
    },
  },
});

export const {
  resetAll,
  setCampaignList,
  setROIDashboardData,
  setROIDashboardCampaignData,
  setROIDashboardDataLoading,
  setROIDashboardForecastBudgetData,
  setCampaignListLoading,
  setROIDashboardDataUpdated,
  setIsCreateCampaignLoading,
  setLatestSavedCampaign,
  setErrMsg,
  setCampaignCreatorData,
  setCampaignDashboardLoading,
  setOutreachBudgetData,
  setOutreachBudgetDataLoading,
  setOutreachNotesData,
  setOutreachNotesDataLoading,
  setOutreachData,
  resetOutreachData,
  setInfluencerAgreementCount,
  setInfluencerAgreementCountLoading,
  setRevisionDetailsData,
  setOutreachEmailAddressesData,
  setOutreachEmailAddressesDataLoading,
  setTikTokSparkAds,
  setAdvertiserIDList,
  setAdvertiserSparkLoading,
  setEnvelopesList,
  setEnvelopesListLoading,
  setReportingDataResponce,
  setReportingData,
  setReportingDataLoading,
} = CampaignsSlice.actions;

export const resetAllCampaigns = () => async dispatch => {
  dispatch(resetAll());
};

export const getCampaignList = (campaignId = null) => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      // Make sure the old data structure is compatible with the new one
      respApi.data.list.forEach(campaign => {
        campaign.personaDataArr.forEach(persona => {
          if (!_.isArray(persona.respData)) {
            return false;
          }
          persona.respData.forEach(segment => {
            const { primaryInterests } = segment;
            if (!_.isArray(primaryInterests)) {
              // convert the old structure to the new one
              segment.primaryInterests = _.keys(primaryInterests);
            }
          });
        });
      });
      dispatch(setCampaignList(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setCampaignListLoading(false));
    if (ENV !== 'PRODUCTION') {
      console.log(respApi);
    }
  };

  const params = campaignId === null ? {} : { campaignId };
  dispatch(setCampaignListLoading(true));

  arClient.doApiCall('/campaign/fetchAll', params, callback);
};

export const getROIDashboardData = campaignId => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      // console.log('campaign resp:', respApi.data);
      const { metrics, budgets } = respApi.data;
      dispatch(setROIDashboardData(Object.assign(metrics, budgets)));
      dispatch(setROIDashboardCampaignData(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setROIDashboardDataLoading(false));
  };

  dispatch(setROIDashboardDataLoading(true));

  arClient.doApiCall(
    '/campaign/getROIDashboardByCampaignId',
    { campaignId },
    callback
  );
};

export const getReportingDashboardData = campaignId => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      console.log('campaign resp:', respApi.data);
      dispatch(setReportingDataResponce(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setReportingDataLoading(false));
  };

  dispatch(setReportingDataLoading(true));

  arClient.doApiCall(
    '/tiktok-creator/get-order-report',
    { campaignId },
    callback
  );
};

export const addUpdateCampaign = values => async dispatch => {
  const callback = resp => {
    dispatch(setIsCreateCampaignLoading(false));
    if (resp.status === 10) {
      dispatch(setLatestSavedCampaign(values.name));
    } else {
      dispatch(setErrMsg(resp.error));
    }
  };

  dispatch(setErrMsg(null));
  dispatch(setIsCreateCampaignLoading(true));

  arClient.doApiCall('/campaign/create', values, callback);
};

export const addToROIDashboard = objParam => async dispatch => {
  const callback = resp => {
    dispatch(setROIDashboardDataUpdated(resp.status === 10));

    setTimeout(() => dispatch(setROIDashboardDataUpdated(null)), 9000); // resets
  };

  arClient.doApiCall('/campaign/addToROIDashboard', objParam, callback);
};

export const setRoiDashForecastBudget = value => async dispatch => {
  dispatch(setROIDashboardForecastBudgetData(value));
};

export const deleteCampaignById = campaignId => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { Campaigns } = getState();
    const callback = resp => {
      if (resp.status === 10) {
        const newList = Campaigns.campaignList.map(campaign => {
          if (campaign.id === campaignId) {
            return {
              ...campaign,
              deleteRequestedAt: new Date(),
            };
          }
          return campaign;
        });
        dispatch(setCampaignList({ list: newList }));
        resolve();
      } else {
        reject();
      }
      dispatch(setCampaignListLoading(false));
    };
    dispatch(setCampaignListLoading(true));
    arClient.doApiCall('/campaign/deleteById', { campaignId }, callback);
  });
};

export const restoreCampaignById = campaignId => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { Campaigns } = getState();

    const callback = resp => {
      if (resp.status === 10) {
        const newList = Campaigns.campaignList.map(campaign => {
          if (campaign.id === campaignId) {
            return {
              ...campaign,
              deleteRequestedAt: null,
            };
          }
          return campaign;
        });

        dispatch(setCampaignList({ list: newList }));
        resolve();
      } else {
        reject();
      }
      dispatch(setCampaignListLoading(false));
    };
    dispatch(setCampaignListLoading(true));
    arClient.doApiCall(
      '/campaign/restorePendingDeleteById',
      { campaignId },
      callback
    );
  });
};

export const deleteROICampaignById = campaignId => async (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const { Campaigns } = getState();

    const callback = resp => {
      if (resp.status === 10) {
        dispatch(
          setROIDashboardCampaignData({
            campaign: {
              ...Campaigns.ROIDashboardCampaignData,
              deleteRequestedAt: new Date(),
            },
          })
        );
        resolve();
      } else {
        reject();
      }
      dispatch(setROIDashboardDataLoading(false));
    };
    dispatch(setROIDashboardDataLoading(true));
    arClient.doApiCall('/campaign/deleteById', { campaignId }, callback);
  });
};

export const restoreROICampaignById = campaignId => async (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const { Campaigns } = getState();

    const callback = resp => {
      if (resp.status === 10) {
        dispatch(
          setROIDashboardCampaignData({
            campaign: {
              ...Campaigns.ROIDashboardCampaignData,
              deleteRequestedAt: null,
            },
          })
        );
        resolve();
      } else {
        reject();
      }
      dispatch(setROIDashboardDataLoading(false));
    };
    dispatch(setROIDashboardDataLoading(true));
    arClient.doApiCall(
      '/campaign/restorePendingDeleteById',
      { campaignId },
      callback
    );
  });
};

export const fetchCampaignCreators = campaignId => dispatch => {
  if (_.isNil(campaignId)) {
    return;
  }

  dispatch(
    setCampaignCreatorData({
      key: 'fetchCampaignCreatorsLoading',
      value: true,
    })
  );
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(
        setCampaignCreatorData({
          key: 'campaignCreators',
          value: [resp.data, resp.indStdER],
        })
      );
      dispatch(
        setCampaignCreatorData({
          key: 'isCampaignCreatorsFetched',
          value: true,
        })
      );
      dispatch(
        setCampaignCreatorData({
          key: 'campaignCreatorsFetchedId',
          value: campaignId,
        })
      );
    }

    dispatch(
      setCampaignCreatorData({
        key: 'fetchCampaignCreatorsLoading',
        value: false,
      })
    );
  };

  arClient.doApiCall('/campaign/fetchCreators', { campaignId }, callback);
};

export const getOutreachBudgetData = creatorReportDetailsId => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setOutreachBudgetData(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setOutreachBudgetDataLoading(false));
  };

  dispatch(setOutreachBudgetDataLoading(true));

  arClient.doApiCall(
    '/creator/getBudget',
    { creatorReportDetailsId },
    callback
  );
};

export const setOutreachBudget = objParam => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setOutreachBudgetDataLoading(false));
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setOutreachBudgetDataLoading(true));

    arClient.doApiCall('/creator/setBudget', objParam, callback);
  });
};

export const setOutreachBudgets = objParam => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setOutreachBudgetDataLoading(false));
      if (resp.status === 10) {
        dispatch(
          setOutreachBudgetData([
            {
              creatorReportDetailsId: objParam.creatorReportDetailsId,
              asking: objParam?.asking || 0,
              proposed: objParam?.proposed || 0,
              remainingBudget: objParam?.remainingBudget || 0,
            },
          ])
        );
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setOutreachBudgetDataLoading(true));

    arClient.doApiCall('/creator/setBudgets', objParam, callback);
  });
};

export const getOutreachNotesData = creatorReportDetailsId => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setOutreachNotesData(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setOutreachNotesDataLoading(false));
  };

  dispatch(setOutreachNotesDataLoading(true));

  arClient.doApiCall(
    '/outreach/getInternalNotes',
    { creatorReportDetailsId },
    callback
  );
};

export const setOutreachNotes = (
  creatorReportDetailsId,
  notesList
) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setOutreachNotesDataLoading(false));
      if (resp.status === 10) {
        dispatch(getOutreachNotesData(creatorReportDetailsId));
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setOutreachNotesDataLoading(true));

    arClient.doApiCall(
      '/outreach/setInternalNotes',
      {
        creatorReportDetailsId,
        notesArr: JSON.stringify(notesList),
      },
      callback
    );
  });
};

export const initOutreach = creatorReportDetailsId => dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const outreachData = _.cloneDeep(resp);
        const { contactDetails = [] } = resp;

        if (_.isNil(contactDetails)) {
          outreachData.contactDetails = [];
        }
        // populate emailTo field
        if (!contactDetails || contactDetails.length === 0) {
          outreachData.emailTo = [];
        } else {
          const emailToObj = contactDetails.find(
            contact => contact.type === 'email'
          );
          if (emailToObj) {
            outreachData.emailTo = [emailToObj.value];
          }
        }
        dispatch(setOutreachData(outreachData));
        resolve();
      } else {
        resolve();
      }
    };
    arClient.doApiCall('/outreach/init', { creatorReportDetailsId }, callback);
  });
};

export const sendOutreachEmail = ({
  outreachId,
  to,
  bcc = JSON.stringify([]),
  cc = JSON.stringify([]),
  subject,
  bodyHTML,
  parentGmailId = null,
  senderInfo = JSON.stringify({
    platform: 'default',
    oauthInfoId: null,
  }),
  isInitialEmail = false,
}) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 'INIT' || resp.status === 10) {
        const { Campaigns } = getState();
        const { outreachData } = Campaigns;
        const emails = _.isArray(outreachData.emails)
          ? _.cloneDeep(outreachData.emails)
          : [];

        // add newly added email to the email list
        emails.push({
          id: uuidv4(),
          createdTimeStamp: Date.now(),
          to,
          subject,
          bodyHTML,
        });
        dispatch(
          setOutreachData({
            ...outreachData,
            emails,
          })
        );
        resolve(true);
      } else {
        reject();
      }
    };

    const payload = {
      subject,
      bodyHTML,
      to,
      bcc,
      cc,
      outreachId,
      senderInfo,
      isInitialEmail,
    };

    if (parentGmailId !== null) {
      payload.parentGmailId = parentGmailId;
    }
    arClient.doApiCall('/outreach/sendEmail', payload, callback);
  });
};

export const fetchInfContentRevision = contentRevisionId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject();
      }
    };

    arClient.doApiCall(
      '/custom/fetch',
      {
        contentRevisionId,
      },
      callback
    );
  });
};

export const fetchInfContentRevisionDetails = contentRevisionDetailsId => dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const { data } = resp.data;
        if (resp.data.status === 10) {
          dispatch(setRevisionDetailsData(data));
          resolve();
        } else {
          reject();
        }
      } else {
        reject();
      }
    };
    arClient.doApiCall(
      '/custom/get-conversation',
      {
        contentRevisionDetailsId,
      },
      callback
    );
  });
};

export const addInfluencerResponse = ({
  contentRevisionDetailsId,
  msgHTML,
  mediaLink = null,
}) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
        const { Campaigns } = getState();
        const { revisionDetailsData } = Campaigns;
        dispatch(fetchInfContentRevisionDetails(contentRevisionDetailsId));
        dispatch(
          setRevisionDetailsData({
            ...revisionDetailsData,
            details: resp.data,
          })
        );
      } else {
        reject();
      }
    };

    arClient.doApiCall(
      '/custom/add-influencer-response',
      {
        contentRevisionDetailsId,
        parentId: null,
        msgHTML,
        mediaLink,
      },
      callback
    );
  });
};

export const infSubmitPublishURL = ({
  contentRevisionDetailsId,
  publishURL,
}) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const { Campaigns } = getState();
        const { revisionDetailsData } = Campaigns;
        resolve(resp.data);
        dispatch(
          setRevisionDetailsData({
            ...revisionDetailsData,
            revisionDetails: {
              ...revisionDetailsData.revisionDetails,
              publishURL,
            },
          })
        );
      } else {
        reject();
      }
    };

    arClient.doApiCall(
      '/custom/add-publish-link',
      {
        contentRevisionDetailsId,
        publishURL,
      },
      callback
    );
  });
};

export const addUpdateCampaignV2 = values => dispatch => {
  // console.log('values new campaign', values);
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setIsCreateCampaignLoading(false));
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject();
      }
    };

    dispatch(setIsCreateCampaignLoading(true));
    arClient.doApiCall('/campaign/createV2', values, callback);
  });
};

export const getAgreementCount = (
  creatorReportDetailsId,
  campaignId
) => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setInfluencerAgreementCount(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setInfluencerAgreementCountLoading(false));
  };

  dispatch(setInfluencerAgreementCountLoading(true));

  arClient.doApiCall(
    '/agreement/get',
    { creatorReportDetailsId, campaignId },
    callback
  );
};

export const setAgreementCount = (
  creatorReportDetailsId,
  campaignId,
  agreementList
) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setInfluencerAgreementCountLoading(false));
      if (resp.status === 10) {
        dispatch(setInfluencerAgreementCount(resp.data));
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setInfluencerAgreementCountLoading(true));

    arClient.doApiCall(
      '/agreement/create',
      {
        creatorReportDetailsId,
        campaignId,
        contentJson: JSON.stringify(agreementList),
      },
      callback
    );
  });
};

export const sendRevisionNotificationEmails = (
  contentRevisionId,
  recipients,
  emailBody
) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/content-revision/sendNotificationEmails',
      {
        contentRevisionId,
        recipients: JSON.stringify(recipients),
        emailBody,
        subject: 'Content Revision Link',
      },
      callback
    );
  });
};

export function updateAgreementContent({
  creatorContentAgreementId,
  agreementList,
}) {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/agreement/updateContentJson',
      {
        creatorContentAgreementId,
        contentJson: JSON.stringify(agreementList),
      },
      callback
    );
  });
}

export const getOutreachEmailAddresses = creatorReportDetailsId => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setOutreachEmailAddressesData(respApi.data));
    } else {
      // error handling here
    }
    dispatch(setOutreachEmailAddressesDataLoading(false));
  };

  dispatch(setOutreachEmailAddressesDataLoading(true));

  arClient.doApiCall(
    '/outreach/getEmailAddresses',
    { creatorReportDetailsId },
    callback
  );
};

export const addOutreachEmailAddresses = (
  creatorReportDetailsId,
  emailsArr,
  id
) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const { Campaigns } = getState();
        const { outreachEmailAddresses } = Campaigns;
        let updatedEmails;
        if (id) {
          updatedEmails = outreachEmailAddresses.map(item =>
            item.id === id
              ? { ...item, role: emailsArr[0].role, email: emailsArr[0].email }
              : item
          );
        } else {
          updatedEmails = Array.isArray(outreachEmailAddresses)
            ? [...outreachEmailAddresses, ...emailsArr]
            : [...emailsArr];
        }
        dispatch(setOutreachEmailAddressesData(updatedEmails));
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/outreach/addEmailAddresses',
      {
        creatorReportDetailsId,
        emailsArr: JSON.stringify(emailsArr),
      },
      callback
    );
  });
};

export const fetchRevisionAnalysis = revisionConvId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        if (_.isEmpty(resp.data)) {
          resolve({
            respData: null,
            status: null,
          });
          return;
        }
        const parsedRespData = JSON.parse(resp.data[0].respData);
        const formattedData = {
          respData: {
            analysis: parsedRespData.Posts['0'],
            meta: parsedRespData.Posts.meta,
          },
          status: resp.data[0].status,
        };
        resolve(formattedData);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/custom/fetch-revision-analysis',
      {
        revisionConvId,
      },
      callback
    );
  });
};

export const deleteOutreachEmailAddress = id => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const { Campaigns } = getState();
        const { outreachEmailAddresses } = Campaigns;
        const filteredEmailList = outreachEmailAddresses.filter(
          item => item.id !== id
        );
        dispatch(setOutreachEmailAddressesData(filteredEmailList));
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/outreach/delEmailAddress',
      {
        id,
      },
      callback
    );
  });
};

export const getOutreachEmailCount = outreachId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/outreach/emailCount',
      {
        outreachId,
      },
      callback
    );
  });
};

export const rerunRevisionAnalysis = contentRevisionConversationId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/content-revision/rerunAnalysis',
      {
        contentRevisionConversationId,
      },
      callback
    );
  });
};

export const authorizeOutlookAccount = (authCode, redirectURI) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/outreach/authorizeOutlookEmailAddress',
      {
        authCode,
        redirectURI,
      },
      callback
    );
  });
};

export const fetchAllSenderEmails = campaignId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        const emails = resp.data || [];

        emails.unshift({
          email: 'noreply@radintel.ai',
          platform: 'default',
          oauthInfoId: null,
        });

        resolve(emails);
      } else {
        reject(resp);
      }
    };

    // return;
    arClient.doApiCall(
      '/outreach/getSenderEmailAddresses',
      {
        campaignId,
      },
      callback
    );
  });
};

// export const fetchAllOutlookConnections() {
//   return new Promise((resolve, reject) => {
//     const callback = resp => {
//       if (resp.status === 10) {
//         const emails = resp.data || [];

//         emails.unshift({
//           email: 'noreply@radintel.ai',
//           platform: 'default',
//           oauthInfoId: null,
//         });

//         resolve(emails);
//       } else {
//         reject(resp);
//       }
//     };

//     // return;
//     arClient.doApiCall(
//       '/outreach/getSenderEmailAddresses',
//       {
//         campaignId,
//       },
//       callback
//     );
//   });
// }

export const removeOAuthConnection = oAuthInfoDetailsId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10 && resp.data == true) {
        resolve(true);
      } else {
        reject();
      }
    };

    // return;
    arClient.doApiCall(
      '/outreach/removeOAuthConnection',
      {
        oAuthInfoDetailsId,
      },
      callback
    );
  });
};

export const getBrandSafetyData = campaignId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        try {
          resolve(resp.data);
        } catch (e) {
          console.error('failed to parse brand safety json object');
        }
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/campaign/getBrandSafety',
      {
        campaignId,
      },
      callback
    );
  });
};

export const getMagicLink = (builderRequestId, segmentId) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/magic-link/fetch',
      {
        builderRequestId,
        segmentId,
      },
      callback
    );
  });
};

export const getInfListByMagicLink = (magicLinkId, hashCode) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/magic-link/getInfList',
      {
        magicLinkId,
        hashCode,
      },
      callback
    );
  });
};

export const createMagicLink = (builderRequestId, segmentId, expiryDate) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/magic-link/create',
      {
        expiryDate,
        builderRequestId,
        segmentId,
      },
      callback
    );
  });
};

export const removeMagicLink = (magicLinkId, hashCode) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/magic-link/remove',
      {
        magicLinkId,
        hashCode,
      },
      callback
    );
  });
};

export const updateMagicLink = (magicLinkId, hashCode, expiryDate) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/magic-link/update',
      {
        magicLinkId,
        hashCode,
        expiryDate,
      },
      callback
    );
  });
};

export const getPostPricing = (requestedPrice, creatorReportDetailsId) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/creator/getPostPricing',
      {
        requestedPrice,
        creatorReportDetailsId,
      },
      callback
    );
  });
};

// Docusign endpoints

// This endpoint will check is for campaign docusign account exists and will return account details.
export const getDocusignInfo = campaignId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/docusign/user-info',
      {
        campaignId,
      },
      callback
    );
  });
};

// This endpoint will return all account templates
export const getDocusignTemplates = campaignId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/docusign/get-templates',
      {
        campaignId,
      },
      callback
    );
  });
};

// This endpoint will send the proposal
export const createEnvelope = (
  campaignId,
  docusignAccountId,
  emailSubject,
  emailBlurb,
  templateId,
  signers,
  creatorReportDetailsId
) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/docusign/create-envelope-from-template',
      {
        campaignId,
        docusignAccountId,
        emailSubject,
        emailBlurb,
        templateId,
        signers: JSON.stringify(signers),
        creatorReportDetailsId,
      },
      callback
    );
  });
};

// this endpoint get the docusign envelopes list
export const getDocusignEnvelopes = (
  campaignId,
  creatorReportDetailsId
) => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setEnvelopesList(respApi.data));
    }
    dispatch(setEnvelopesListLoading(false));
  };

  dispatch(setEnvelopesListLoading(true));

  arClient.doApiCall(
    '/docusign/get-list-envelopes',
    { campaignId, creatorReportDetailsId },
    callback
  );
};

// This endpoint will return embed URL array for each recipient
export const getDocusignEmbedURL = (campaignId, envelopeId, currentUrl) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/docusign/envelope-embed-view',
      {
        campaignId,
        envelopeId,
        localUri: currentUrl,
      },
      callback
    );
  });
};

export default CampaignsSlice.reducer;
